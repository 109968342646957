import ButtonPrimary from 'components/Button/ButtonPrimary';
import Card10 from 'components/Card10/Card10';
import Card11 from 'components/Card11/Card11';
import Card14 from 'components/Card14/Card14';
import Card3 from 'components/Card3/Card3';
import Card4 from 'components/Card4/Card4';
import Card7 from 'components/Card7/Card7';
import Card9 from 'components/Card9/Card9';
import Heading from 'components/Heading/Heading';
import WidgetCategories from 'components/WidgetCategories/WidgetCategories';
import { PostDataType } from 'data/types';
import { FC, useState } from 'react';
import { usePostsQuery } from '../../framework/rest/post/posts.query';
import Card5Edit from '../Card5/Card5Edit';

export interface SectionLatestPostsProps {
    posts?: PostDataType[];
    gridClass?: string;
    className?: string;
    heading?: string;
    postCardName?: 'card3' | 'card4' | 'card7' | 'card9' | 'card10' | 'card11' | 'card14';
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
    posts = [],
    postCardName = 'card3',
    heading = 'Son güncellemeler',
    gridClass = '',
    className = '',
}) => {
    const [currentLimit, setCurrentLimit] = useState(5);
    const { data: list, isRefetching } = usePostsQuery({
        limit: currentLimit,
    });

    const renderCard = (post: any, index: number) => {
        switch (post.type) {
            case 'card3':
                return (
                    <Card3
                        key={index}
                        className="py-3"
                        post={post}
                    />
                );
            case 'card4':
                return (
                    <Card4
                        key={index}
                        post={post}
                    />
                );
            case 'card7':
                return (
                    <Card7
                        key={index}
                        post={post}
                        ratio="aspect-w-5 aspect-h-5"
                    />
                );
            case 'card9':
                return (
                    <Card9
                        key={index}
                        post={post}
                    />
                );
            case 'card10':
                return (
                    <Card10
                        key={index}
                        post={post}
                    />
                );
            case 'card11':
                return (
                    <Card11
                        key={index}
                        post={post}
                    />
                );
            case 'card14':
                return (
                    <Card14
                        key={index}
                        post={post}
                    />
                );
            case 'video':
                return (
                    <Card5Edit
                        key={index}
                        post={post}
                    />
                );
            case 'photo':
                return (
                    <Card5Edit
                        key={index}
                        post={post}
                    />
                );
            case 'textual':
                return (
                    <Card5Edit
                        key={index}
                        post={post}
                    />
                );
            default:
                return (
                    <Card3
                        key={index}
                        className="py-3"
                        post={post}
                    />
                );
        }
    };

    const handlePaginate = () => {
        setCurrentLimit(prev => prev + 5);
    };

    return (
        <div className={`nc-SectionLatestPosts relative ${className}`}>
            <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14 relative">
                    <Heading>{heading}</Heading>
                    <div className={`grid gap-6 md:gap-8 ${gridClass}`}>
                        {/*{posts.map(renderCard)}*/}
                        {list?.posts.data
                            .filter(x => x.status === 'publish')
                            .sort(function (a, b) {
                                var keyA = new Date(a?.published_at!),
                                    keyB = new Date(b?.published_at!);
                                if (keyA < keyB) return 1;
                                if (keyA > keyB) return -1;
                                return 0;
                            })
                            .map(renderCard)}
                    </div>
                    {list?.posts?.paginatorInfo?.hasMorePages && (
                        <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
                            <ButtonPrimary
                                loading={isRefetching}
                                onClick={handlePaginate}
                            >
                                Daha Fazlasını Keşfet
                            </ButtonPrimary>
                        </div>
                    )}
                </div>
                <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 relative">
                    {/* <WidgetTags /> */}
                    <WidgetCategories hasNextPage={list?.posts?.paginatorInfo?.hasMorePages} />
                    {/* <WidgetAuthors /> */}
                    {/* <WidgetPosts /> */}
                </div>
            </div>
        </div>
    );
};

export default SectionLatestPosts;
