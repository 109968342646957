import CategoryBadgeList from 'components/CategoryBadgeList/CategoryBadgeList';
import Link from 'components/Link';
import PostCardLikeAndComment from 'components/PostCardLikeAndComment/PostCardLikeAndComment';
import PostCardMeta from 'components/PostCardMeta/PostCardMeta';
import PostCardSaveAction from 'components/PostCardSaveAction/PostCardSaveAction';
import PostFeaturedMedia from 'components/PostFeaturedMedia/PostFeaturedMedia';
import { PostDataType } from 'data/types';
import { FC, useState } from 'react';

export interface Card11Props {
    className?: string;
    post: PostDataType;
    ratio?: string;
    hiddenAuthor?: boolean;
}

const Card11: FC<Card11Props> = ({
    className = 'h-full',
    post,
    hiddenAuthor = false,
    ratio = 'aspect-w-4 aspect-h-3',
}) => {
    const { title, post_title, href, categories, date, type, id, slug } = post;

    const [isHover, setIsHover] = useState(false);
    let url = '#';

    if (type === 'textual' || type === 'photo' || type === 'video') {
        url = `/blogs/${slug}`;
    }

    return (
        <div
            className={`nc-Card11 relative flex flex-col group rounded-3xl bg-white dark:bg-neutral-900 ${className}`}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            //
        >
            <div className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}>
                <div>
                    <PostFeaturedMedia
                        post={post}
                        isHover={isHover}
                    />
                </div>
            </div>
            <Link
                href={href ?? url}
                className="absolute inset-0"
            ></Link>
            <span className="absolute top-3 inset-x-3 z-10">
                <CategoryBadgeList categories={categories} />
            </span>

            <div className="p-4 flex flex-col space-y-3 h-full justify-between">
                {!hiddenAuthor ? (
                    <PostCardMeta meta={post} />
                ) : (
                    <span className="text-xs text-neutral-500">{date}</span>
                )}
                <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
                    <span
                        className="line-clamp-2"
                        title={title ?? post_title}
                    >
                        {title ?? post_title}
                    </span>
                </h3>
                <div className="flex items-end justify-between mt-auto">
                    <PostCardLikeAndComment className="relative" />
                    <PostCardSaveAction
                        className="relative"
                        post={post}
                    />
                </div>
            </div>
        </div>
    );
};

export default Card11;
