import CardAuthor2 from 'components/CardAuthor2/CardAuthor2';
import CategoryBadgeList from 'components/CategoryBadgeList/CategoryBadgeList';
import Link from 'components/Link';
import NcImage from 'components/NcImage/NcImage';
import NextPrev from 'components/NextPrev/NextPrev';
import PostCardLikeAndComment from 'components/PostCardLikeAndComment/PostCardLikeAndComment';
import PostCardSaveAction from 'components/PostCardSaveAction/PostCardSaveAction';
import { PostDataType } from 'data/types';
import { FC } from 'react';

export interface CardLarge1Props {
    className?: string;
    post: PostDataType;
    onClickNext?: () => void;
    onClickPrev?: () => void;
}

const CardLarge1: FC<CardLarge1Props> = ({ className = '', post, onClickNext = () => {}, onClickPrev = () => {} }) => {
    const { user_profiles, post_title, title, type, image, thumbnail_image, users, author, tags, published_at, slug } =
        post;

    let url = '#';

    if (type === 'textual' || type === 'photo' || type === 'video') {
        url = `/blogs/${slug}`;
    }

    return (
        <div
            className={`nc-CardLarge1 nc-CardLarge1--hasAnimation relative flex flex-col-reverse lg:flex-row justify-end ${className}`}
        >
            <div className="lg:absolute z-10 lg:left-0 lg:top-1/2 lg:-translate-y-1/2 w-full -mt-8 lg:mt-0 px-3 sm:px-6 lg:px-0 lg:w-2/5">
                <div className="nc-CardLarge1__left p-4 sm:p-8 xl:py-14 lg:px-10 bg-white/40 dark:bg-neutral-900/40 backdrop-filter backdrop-blur-lg shadow-lg dark:shadow-2xl rounded-3xl space-y-3 sm:space-y-5 relative z-50">
                    <CategoryBadgeList categories={tags} />

                    <h2 className="nc-card-title text-base sm:text-xl lg:text-2xl font-semibold ">
                        <Link
                            href={url}
                            className="line-clamp-2"
                            title={post_title}
                        >
                            {post_title}
                        </Link>
                    </h2>

                    <CardAuthor2
                        className="relative"
                        author={author}
                        users={users!}
                        user_profiles={user_profiles!}
                        date={published_at!}
                        url={url}
                    />

                    <div className="flex items-center justify-between mt-auto">
                        <PostCardLikeAndComment />
                        <PostCardSaveAction
                            bookmarkClass="h-8 w-8 bg-neutral-50/30 hover:bg-neutral-50/50 dark:bg-neutral-800/30 dark:hover:bg-neutral-800/50"
                            post={post}
                        />
                    </div>
                </div>
                <div className="p-4 sm:pt-8 sm:px-10 flex">
                    <NextPrev
                        btnClassName="w-11 h-11 text-xl"
                        onClickNext={onClickNext}
                        onClickPrev={onClickPrev}
                    />
                </div>
            </div>
            <div className="w-full lg:w-2/3">
                <Link
                    href={url}
                    className="nc-CardLarge1__right block relative"
                >
                    <NcImage
                        containerClassName="aspect-w-16 aspect-h-12 sm:aspect-h-9 md:aspect-h-14 lg:aspect-h-10 2xl:aspect-h-9 relative"
                        className="absolute inset-0 object-cover rounded-3xl"
                        src={
                            (thumbnail_image?.original ?? thumbnail_image?.thumbnail) ||
                            (image?.original ?? image?.thumbnail)
                        }
                        alt={title}
                        fill
                        sizes="(max-width: 768px) 100vw, 50vw"
                    />
                    {/* META TYPE */}
                    {/* <PostTypeFeaturedIcon
                        postType={post.postType}
                        className="absolute w-8 h-8 md:w-10 md:h-10 right-6 top-6"
                    /> */}
                </Link>
            </div>
        </div>
    );
};

export default CardLarge1;
