import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import useIsMobile from 'hooks/useIsMobile';
import rightImgDemo from 'images/kips-anasayfa.png';
import { FC } from 'react';
import ReactPlayer from 'react-player';

export interface SectionBecomeAnAuthorProps {
    className?: string;
    rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({ className = '', rightImg = rightImgDemo }) => {
    const isMobile = useIsMobile();

    function handleStart() {
        window.location.href = `${process.env.REACT_APP_START_URL}/explore`;
    }

    return (
        <div className={`nc-SectionBecomeAnAuthor relative flex flex-col items-center ${className}`}>
            <div className="flex-shrink-0 mb-8 w-full flex flex-col items-center justify-center">
                <h2 className="font-semibold text-3xl sm:text-4xl mb-4">Türkiye'nin E-Ticaret Altyapısı</h2>
                <span className="text-xs uppercase tracking-wider font-medium text-neutral-400 text-center">
                    BAŞARILI BİR E-TİCARET İÇİN İHTİYACINIZ OLAN HER ŞEY
                </span>
            </div>
            <ReactPlayer
                controls
                url={'https://youtu.be/7ZneNS7olAk'}
            />
            <div className="mt-8 mb-0 lg:mb-8 w-full flex flex-col lg:flex-row items-center justify-between footer-area">
                <div className="block lg:hidden text-neutral-500 dark:text-neutral-400 w-full lg:w-9/12 mb-8 lg:mb-0">
                    <span className="block text-center">
                        Hiçbir ücret ödemeden hesabınızı kolayca oluşturun, kodlama olmadan hayalinizdeki websitenizi
                        anında yayınlayın
                    </span>
                </div>

                <div className="hidden lg:block text-neutral-500 dark:text-neutral-400 lg:w-9/12 mb-8 lg:mb-0">
                    <span className="block">Hiçbir ücret ödemeden hesabınızı kolayca oluşturun,</span>
                    <span className="block">kodlama olmadan hayalinizdeki websitenizi anında yayınlayın</span>
                </div>

                <ButtonPrimary
                    onClick={handleStart}
                    className="w-fit"
                >
                    <span>E-Ticaret Siteni Aç</span>
                    <ChevronDoubleRightIcon className="h-6 w-6 ml-4" />
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default SectionBecomeAnAuthor;
