import Image from 'components/Image';
import Link from 'components/Link';
import PostCardMeta from 'components/PostCardMeta/PostCardMeta';
import { PostDataType } from 'data/types';
import { FC } from 'react';

export interface Card3SmallProps {
    className?: string;
    post: PostDataType;
}

const Card3Small: FC<Card3SmallProps> = ({ className = 'h-full', post }) => {
    const { post_title: title, href, type, image, featuredImage, id, slug } = post;

    let url = '#';

    if (type === 'textual' || type === 'photo' || type === 'video') {
        url = `/blogs/${slug}`;
    }

    return (
        <div className={`nc-Card3Small relative flex flex-row justify-between items-center ${className}`}>
            <Link
                href={href ?? url}
                className="absolute inset-0"
                title={title}
            ></Link>
            <div className="relative space-y-2">
                <PostCardMeta meta={{ ...post }} />
                <h2 className="nc-card-title block text-sm sm:text-base font-medium sm:font-semibold text-neutral-900 dark:text-neutral-100">
                    <Link
                        href={href ?? url}
                        className="line-clamp-2"
                        title={title}
                    >
                        {title}
                    </Link>
                </h2>
            </div>

            <Link
                href={href ?? url}
                title={title}
                className={`block w-20 flex-shrink-0 relative rounded-lg overflow-hidden z-0 ml-4 group`}
            >
                <div className={`w-full h-0 aspect-w-1 aspect-h-1`}>
                    <Image
                        alt="featured"
                        sizes="100px"
                        className="object-cover w-full h-full group-hover:scale-110 transform transition-transform duration-300"
                        src={featuredImage ?? image?.original}
                        fill
                        title={title}
                    />
                </div>
            </Link>
        </div>
    );
};

export default Card3Small;
