import { ChevronDoubleRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { Disclosure } from 'app/headlessui';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import ButtonClose from 'components/ButtonClose/ButtonClose';
import Link from 'components/Link';
import Logo from 'components/Logo/Logo';
import SocialsList from 'components/SocialsList/SocialsList';
import SwitchDarkMode from 'components/SwitchDarkMode/SwitchDarkMode';
import { useMenuQuery } from 'framework/rest/menu/use-menu.query';
import { useAtom } from 'jotai';
import React from 'react';
import { generalSettingsAtom } from 'store/atom/general-settings';
import { NavItemType } from './NavigationItem';
// import { useMegaMenuQuery } from "framework/rest/menu/use-mega-menu.query";

export interface NavMobileProps {
    data?: NavItemType[];
    onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
    // data = NAVIGATION_DEMO_2,
    onClickClose,
}) => {
    const { data, isLoading: loading } = useMenuQuery();
    const [generalSettings] = useAtom(generalSettingsAtom);
    const { socials: socialsData } = generalSettings?.contactDetails || {};
    // const { data: megaMenuData } = useMegaMenuQuery();
    const _renderMenuChild = (
        item: NavItemType,
        itemClass = ' pl-3 text-neutral-900 dark:text-neutral-200 font-medium ',
    ) => {
        return (
            <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
                {item.sub_menu?.map((item, index) => (
                    <Disclosure
                        key={index}
                        as="li"
                    >
                        <Link
                            href={{
                                pathname: item.url || undefined,
                            }}
                            className={`flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
                        >
                            <span
                                className={`py-2.5 ${!item?.sub_menu ? 'block w-full' : ''}`}
                                onClick={onClickClose}
                            >
                                {item.name}
                            </span>
                            {item?.sub_menu.length > 0 ? (
                                <span
                                    className="flex items-center flex-grow"
                                    onClick={e => e.preventDefault()}
                                >
                                    <Disclosure.Button
                                        as="span"
                                        className="flex justify-end flex-grow"
                                    >
                                        <ChevronDownIcon
                                            className="ml-2 h-4 w-4 text-slate-500"
                                            aria-hidden="true"
                                        />
                                    </Disclosure.Button>
                                </span>
                            ) : null}
                        </Link>
                        {item?.sub_menu.map((item: any, index: number) => (
                            <Disclosure.Panel>
                                {_renderMenuChild(item, 'pl-3 text-slate-600 dark:text-slate-400 ')}
                            </Disclosure.Panel>
                        ))}
                    </Disclosure>
                ))}
            </ul>
        );
    };

    const _renderItem = (item: NavItemType, index: number) => {
        return (
            <Disclosure
                key={index}
                as="li"
                className="text-slate-900 dark:text-white"
            >
                <Link
                    className="flex w-full items-center py-2.5 px-4 font-medium tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
                    href={item.url || ''}
                >
                    <span
                        className={!item.children ? 'block w-full' : ''}
                        onClick={onClickClose}
                    >
                        {item.name}
                    </span>
                    {item?.sub_menu?.length || item?.children?.length ? (
                        <span
                            className="block flex-grow"
                            onClick={e => e.preventDefault()}
                        >
                            <Disclosure.Button
                                as="span"
                                className="flex justify-end flex-grow"
                            >
                                <ChevronDownIcon
                                    className="ml-2 h-4 w-4 text-neutral-500"
                                    aria-hidden="true"
                                />
                            </Disclosure.Button>
                        </span>
                    ) : null}
                </Link>
                {item.sub_menu && <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>}
            </Disclosure>
        );
    };

    const renderMagnifyingGlassIcon = () => {
        return (
            <svg
                width={22}
                height={22}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    };

    const renderSearchForm = () => {
        return (
            <form
                action=""
                method="POST"
                className="flex-1 text-slate-900 dark:text-slate-200"
            >
                <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1 py-2 px-4 rounded-xl h-full">
                    {renderMagnifyingGlassIcon()}
                    <input
                        type="search"
                        placeholder="Type and press enter"
                        className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-sm "
                    />
                </div>
                <input
                    type="submit"
                    hidden
                    value=""
                />
            </form>
        );
    };

    return (
        <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
            <div className="py-6 px-5">
                <Logo />
                {/* <div className="flex flex-col mt-5 text-slate-600 dark:text-slate-300 text-sm">
                    <span>Türkiye'nin E-Ticaret Altyapısı</span>
                </div> */}
                <span className="absolute right-2 top-2 p-1">
                    <ButtonClose onClick={onClickClose} />
                </span>

                {/* <div className="mt-5">{renderSearchForm()}</div> */}
            </div>
            <ul className="flex flex-col py-6 px-2 space-y-1">{data?.data.map(_renderItem)}</ul>
            <div className="flex items-center justify-start py-6 px-5 space-x-2">
                <SocialsList
                    socials={socialsData}
                    itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl"
                />
            </div>
            <div className="flex items-center justify-between py-6 px-5 space-x-2">
                <ButtonPrimary
                    href={`${process.env.REACT_APP_START_URL}/explore`}
                    className="!px-10"
                >
                    <span>E-Ticaret Siteni Aç</span>
                    <ChevronDoubleRightIcon className="h-6 w-6 ml-4" />
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default NavMobile;
