import Link from 'components/Link';
import { useThemeMode } from 'hooks/useThemeMode';
import logoLightImg from 'images/logo-light.png';
import logoImg from 'images/logo.png';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { generalSettingsAtom } from 'store/atom/general-settings';

export interface LogoProps {
    img?: string;
    imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({ img = logoImg, imgLight = logoLightImg }) => {
    const [settings] = useAtom(generalSettingsAtom);
    const { isDarkMode } = useThemeMode();

    // SEO settings
    useEffect(() => {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        favicon.href = settings?.seo?.favicon?.original ?? '';
        const title = document.getElementById('site-title') as HTMLTitleElement;
        title.innerText = settings?.siteTitle ? `${settings?.siteTitle} | ${settings?.siteSubtitle}` : '';
        const description = document.getElementById('meta-description') as HTMLMetaElement;
        description.content = settings?.seo?.description || '';
        const ogTitle = document.getElementById('og-title') as HTMLMetaElement;
        ogTitle.content = settings?.seo?.ogTitle || '';
        const ogDescription = document.getElementById('og-description') as HTMLMetaElement;
        ogDescription.content = settings?.seo?.ogDescription || '';
        const ogImage = document.getElementById('og-image') as HTMLMetaElement;
        ogImage.content = settings?.seo?.ogImage?.original || '';
        const appIcon = document.getElementById('app_icon') as HTMLLinkElement;
        appIcon.href = settings?.seo?.ogImage?.original || '';
        const appIconMd = document.getElementById('app_icon_md') as HTMLLinkElement;
        appIconMd.href = settings?.seo?.ogImage?.original || '';
    }, [settings]);

    return (
        <Link
            href="/"
            className="ttnc-logo inline-block text-primary-6000 flex-shrink-0"
        >
            {isDarkMode
                ? settings?.darkLogo?.original && (
                      <img
                          src={settings?.darkLogo?.original || ''}
                          alt="logo"
                          className="w-32"
                      />
                  )
                : settings?.logo?.original && (
                      <img
                          src={settings?.logo?.original || ''}
                          alt="logo"
                          className="w-32"
                      />
                  )}
        </Link>
    );
};

export default Logo;
